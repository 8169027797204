import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit'

import { fetchRegionById } from '../regionSelector/regionSlice';
import constellationService from './constellationService'

export const fetchConstellationById = createAsyncThunk(
    'constellations/fetchByID',
    async (constellationId) => {
        const { data } = await constellationService.fetchByConstellationID(constellationId)
        return data
    }
)

export const constellationSlice = createSlice({
    name: 'constellation',
    initialState: {
        list: [],
        loading: false,
        selectedConstellation: null
    },
    reducers: {},
    extraReducers: {
        [fetchRegionById.pending]: (state) => {
            state.loading = true
        },
        [fetchConstellationById.fulfilled]: (state, action) => {
            state.selectedConstellation = action.payload.region.constellations[0]
        },
        [fetchRegionById.fulfilled]: (state, action) => {
            state.list = action.payload.constellations
            state.loading = false
        }
    }
})

export default constellationSlice.reducer