import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSystemById } from './systemSlice'
import styles from './SystemSelector.module.css'

import { Select, Spin } from 'antd'
const { Option } = Select

export function SystemSelector() {
    const didMount = useRef(false)
    const dispatch = useDispatch()
    const systems = useSelector(state => state.system.list)
    const isSystemLoading = useSelector(state => state.system.loading);

    const [selectedSystem, setSystem] = useState('')

    useEffect(() => {
      if (didMount.current) {
        if (!!selectedSystem)
          dispatch(fetchSystemById(selectedSystem))
      } else {
        didMount.current = true
      }
    })

    return (
      <Spin spinning={isSystemLoading}>
        <div className={styles.selector}>
            <Select style={{ width: 200 }} 
            disabled={systems.length < 1}
            placeholder="Select a system"
            onChange={(system_id) => setSystem(system_id)}
            showSearch
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {systems.map(system => <Option value={system.system_id} key={system.system_id}>{system.name}</Option>)}
            </Select>
        </div>
      </Spin>
    )
}