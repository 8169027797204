import axios from 'axios';
const baseURL = 'https://api.kasoyi.com/api/reports'
const ax = axios.create({
    baseURL,
    headers: {
        'Authorization': 'zRws84e9iKZWcJVT2hnudUQQtf4K2ZQY3c43ZWAL46gbQU7RqmWY2pPxWbhxFi3h'
    },
});

export default {
    submitReport: (report) => ax.post(baseURL, report),
    fetchAllReports: (report) => ax.get()
}