import React from 'react';
import { useStore, useSelector } from 'react-redux'

import { RegionSelector } from './components/regionSelector/RegionSelector';
import { ConstellationSelector } from './components/constellationSelector/ConstellationSelector';
import { SystemSelector } from './components/systemSelector/SystemSelector';
import { IntelReportForm } from './components/intelReport/IntelReportForm';
import { IntelReportTable } from './components/intelReport/IntelReportTable';

import { Layout, Menu, Breadcrumb, Button } from 'antd';
import { FileExclamationOutlined } from '@ant-design/icons'

import { intelReportSlice } from './components/intelReport/intelReportSlice';

import './App.css';

const { Header, Content } = Layout;

function App() {
  const { show: showIntelReport } = intelReportSlice.actions;
  const store = useStore();
  const hasSelectedSystem = useSelector(state => state.system.selectedSystem !== null)

  return (
    <Layout>
      <Header>
        <div className="container">
          <div className="tm-flex-row ai-center">
            <div className="logo"/>
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
              <Menu.Item key="1">KASOYI INTEL</Menu.Item>
            </Menu>
          </div>
        </div>
      </Header>
      <Content>
        <div className="container">
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Intel</Breadcrumb.Item>
          </Breadcrumb>
          <div className="main-content">
            <h2>Intel</h2>
            <div className="selector-container">
              <RegionSelector />
              <ConstellationSelector />
              <SystemSelector />
              <Button 
                style={{marginLeft: 'auto'}} 
                type="primary"
                disabled={!hasSelectedSystem}
                onClick={() => store.dispatch(showIntelReport())}><FileExclamationOutlined /> Report Intel</Button>
            </div>
            <div className="map-container">

            </div>
            <h2>Reports</h2>
            <IntelReportTable />
            <IntelReportForm />
          </div>
        </div>
      </Content>
    </Layout>
  );
}

export default App;
