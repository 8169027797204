import axios from 'axios';
const baseURL = 'https://api.kasoyi.com/api';
const ax = axios.create({
    baseURL
});

export default {
    fetchAllRegions: () => {
        return ax.get('/regions')
    },
    fetchByRegionID: (regionId) => {
        return ax.get(`/region/${regionId}`)
    }
}