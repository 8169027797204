import React, { useState } from 'react'
import { useStore, useSelector, } from 'react-redux'
import { Modal, Form, Input, Select, Divider, Row, Col, Button, Spin, notification } from 'antd';
import { PlusSquareTwoTone } from '@ant-design/icons';
import update from 'immutability-helper';

import { intelReportSlice } from './intelReportSlice'
import styles from './intelReportForm.module.css'

import intelReportService from '../../services/intelReport.service';

const { Option } = Select;

export function IntelReportForm() {
  const selectedSystem = useSelector(state => state.system.selectedSystem)
  const isVisible = useSelector(state => state.intelReport.isVisible)
  const { hide } = intelReportSlice.actions;
  const store = useStore();

  const layout = {
    labelCol: { span: 8, },
    wrapperCol: { span: 16, },
  };

  const shipDataTemplate = {
    name: '',
    corp: '',
    standing: 'hostile',
    type: 'frigate'
  }

  const [ships, setShips] = useState([])
  const [reportType, setReportType] = useState('sighting')
  const [isSpinning, setIsSpinning] = useState(false)
  
  const addShip = () => {
    setShips(ships.concat(Object.assign({}, shipDataTemplate)))
  }

  const updateShipField = (index, field, value) => {
    setShips(
      update(ships, {
        [index]: {
          [field]: {
            $set: value
          }
        }
      })
    );
  }

  const handleSubmit = () => {
    const payload = Object.assign({
      report: {
        system_id: selectedSystem.system_id,
        type: reportType,
        ships
      }
    })
    setIsSpinning(true)
    intelReportService.submitReport(payload).then(rs => {
      setIsSpinning(false)
      if (rs.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Report sent!'
        });
        store.dispatch(hide());
      }
    })
  }

  const _renderShipRepeater = () => ships.map((ship,i) => {
    return (
      <div key={i}>
        <Divider orientation="left" plain>Ship Data #{i+1}</Divider>
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item label="Name" name={`ships[${i}].name`} labelCol={4} wrapperCol={20}>
              <Input onChange={({ target: { value }}) => updateShipField(i, 'name', value)}/>
            </Form.Item>

            <Form.Item label="Corp" name={`ships[${i}].copr`} labelCol={4} wrapperCol={20}>
            <Input onChange={({ target: { value }}) => updateShipField(i, 'corp', value)}/>
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item label="Standing" name={`ships[${i}].standing`} labelCol={6} wrapperCol={18}>
              <Select placeholder="Select Ship Standing" 
                onSelect={value => updateShipField(i, 'standing', value)}>
                <Option value="hostile">Hostile</Option>
                <Option value="neutral">Neutral</Option>
              </Select>
            </Form.Item>
            
            <Form.Item label="Type" name={`ships[${i}].type`} labelCol={6} wrapperCol={18}>
              <Select placeholder="Select Ship Type" 
                onSelect={value => updateShipField(i, 'type', value)}>
                <Option value="frigate">Frigate</Option>
                <Option value="destroyer">Destroyer</Option>
                <Option value="cruiser">Cruiser</Option>
                <Option value="battlecruiser">Battlecruiser</Option>
                <Option value="battleship">Battleship</Option>
                <Option value="outpost">Outpost</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    )
  })

  return (
      <Modal
        destroyOnClose={true}
        width={800}
        title="Intel Report"
        visible={isVisible}
        onCancel={() => store.dispatch(hide())}
        onOk={handleSubmit}
        okText="Submit"
        >
        <Spin spinning={isSpinning}>
          <Form {...layout} preserve={false}>
            <Form.Item
              label="Report Type"
              name="report_type"
              rules={[
                {
                  required: true,
                  message: 'Report Type required',
                },
              ]}>
              <Select onChange={setReportType} placeholder="Select a report type" >
                <Option value="sighting">Sighting</Option>
                <Option value="engangement">Engagement</Option>
              </Select>
            </Form.Item>
            <div style={{textAlign: 'right'}}>
              <Button type="primary" onClick={addShip}><PlusSquareTwoTone /> Add Ship</Button>
            </div>
            <div className={styles['overflow-container']}>
            {_renderShipRepeater()}
            </div>            
          </Form>
        </Spin>
      </Modal>
  )
}
