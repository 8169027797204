import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table, Tag } from 'antd';

import { fetchAllReports } from './intelReportSlice'

export function IntelReportTable() {
  const didMount = useRef(false)
  const dispatch = useDispatch()

  const reports = useSelector(state => state.intelReport.list)

  useEffect(() => {
    if (didMount.current) {
    } else {
      didMount.current = true
      dispatch(fetchAllReports())
    }
  })
  const columns = [
    {
      title: 'System ID',
      dataIndex: 'system_id',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Ships',
      dataIndex: 'ships',
      render: ships => ships.map((s,i) => {
        return (
          <Tag key={i}>{s.type}</Tag>
        )
      })
    },
    {
      title: 'Report Time',
      dataIndex: 'created',
      render: time => new Date(time).toGMTString()
    },
    {
      title: 'Status',
      dataIndex: 'status',
    }
  ];

  console.log(reports);

  return (
    <Table dataSource={reports} columns={columns} rowKey="_id">
    </Table>
  )
}