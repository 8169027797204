import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllRegions, fetchRegionById } from './regionSlice'
import styles from './RegionSelector.module.css'

import { Select, Spin } from 'antd'
const { Option } = Select

export function RegionSelector() {
    const didMount = useRef(false)
    const dispatch = useDispatch()
    const regions = useSelector(state => state.region.list)
    const isRegionLoading = useSelector(state => state.region.loading)
    const [selectedRegion, setRegion] = useState('')

    useEffect(() => {
      if (didMount.current) {
        if (!!selectedRegion)
          dispatch(fetchRegionById(selectedRegion))
      } else {
        didMount.current = true
        dispatch(fetchAllRegions())
      }
    })

    return (
      <Spin spinning={isRegionLoading}>
        <div className={styles.selector}>
          <Select style={{ width: 200 }} 
            showSearch 
            placeholder="Select a region"
            onChange={(region_id) => setRegion(region_id)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            >
            {regions.map(region => <Option value={region.region_id} key={region.region_id}>{region.name}</Option>)}
          </Select>
        </div>
      </Spin>
    )
}