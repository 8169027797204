import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchConstellationById } from './constellationSlice'
import styles from './ConstellationSelector.module.css'

import { Select, Spin } from 'antd'
const { Option } = Select

export function ConstellationSelector() {
    const didMount = useRef(false)
    const dispatch = useDispatch()
    const constellations = useSelector(state => state.constellation.list)
    const isConstellationLoading = useSelector(state => state.constellation.loading);

    const [selectedConstellation, setConstellation] = useState('')

    useEffect(() => {
      if (didMount.current) {
        if (!!selectedConstellation)
          dispatch(fetchConstellationById(selectedConstellation))
      } else {
        didMount.current = true
      }
    })

    return (
      <Spin spinning={isConstellationLoading}>
        <div className={styles.selector}>
          <Select style={{ width: 200 }} 
            disabled={constellations.length < 1}
            placeholder="Select a constellation"
            onChange={(constellation_id) => setConstellation(constellation_id)}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {constellations.map(constellation => <Option value={constellation.constellation_id} key={constellation.constellation_id}>{constellation.name}</Option>)}
          </Select>
        </div>
      </Spin>
    )
}