import { configureStore } from '@reduxjs/toolkit';
import regionReducer from '../components/regionSelector/regionSlice';
import constellationReducer from '../components/constellationSelector/constellationSlice';
import systemReducer from '../components/systemSelector/systemSlice';
import intelReportReducer from '../components/intelReport/intelReportSlice';

export default configureStore({
  reducer: {
    region: regionReducer,
    constellation: constellationReducer,
    system: systemReducer,
    intelReport: intelReportReducer
  },
});
