import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit'

import { fetchConstellationById } from '../constellationSelector/constellationSlice';
import systemService from './systemService'

export const fetchSystemById = createAsyncThunk(
    'systems/fetchByID',
    async (systemId) => {
        const { data } = await systemService.fetchBySystemID(systemId)
        return data
    }
)

export const systemSlice = createSlice({
    name: 'system',
    initialState: {
        list: [],
        selectedSystem: null,
        loading: false
    },
    reducers: {},
    extraReducers: {
        [fetchSystemById.fulfilled]: (state, action) => {
            state.selectedSystem = action.payload.region.constellations[0].systems[0]
        },
        [fetchConstellationById.pending]: (state) => {
            state.loading = true;
        },
        [fetchConstellationById.fulfilled]: (state, action) => {
            state.list = action.payload.region.constellations[0].systems;
            state.loading = false;
        }
    }
})

export default systemSlice.reducer