import axios from 'axios';
const baseURL = 'https://api.kasoyi.com/api';
const ax = axios.create({
    baseURL
});

export default {
    fetchByConstellationID: (constellationId) => {
        return ax.get(`/constellation/${constellationId}`)
    }
}