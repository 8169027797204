import axios from 'axios';
const baseURL = 'https://api.kasoyi.com/api';
const ax = axios.create({
    baseURL
});

export default {
    fetchBySystemID: (systemId) => {
        return ax.get(`/system/${systemId}`)
    }
}