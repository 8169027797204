import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit'

import intelReportService from '../../services/intelReport.service';

export const fetchAllReports = createAsyncThunk(
  'reports/fetchAll',
  async () => {
      const { data: { reports } } = await intelReportService.fetchAllReports()
      return reports
  }
)

export const intelReportSlice = createSlice({
  name: 'intelReport',
  initialState: {
    isVisible: false,
    list: []
  },
  reducers: {
    show: state => { state.isVisible = true },
    hide: state => { state.isVisible = false }
  },
  extraReducers: {
    [fetchAllReports.fulfilled]: (state, action) => {
      state.list = action.payload
    }
  }
});

export default intelReportSlice.reducer