import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit'
import regionService from './regionService'

export const fetchAllRegions = createAsyncThunk(
    'regions/fetchAll',
    async () => {
        const { data: { regions } } = await regionService.fetchAllRegions()
        return regions
    }
)

export const fetchRegionById = createAsyncThunk(
    'regions/fetchByID',
    async (regionId) => {
        const { data: { region } } = await regionService.fetchByRegionID(regionId)
        return region
    }
)

export const regionSlice = createSlice({
    name: 'region',
    initialState: {
        loading: 'idle',
        list: [],
        selectedRegion: null
    },
    reducers: {},
    extraReducers: {
        [fetchAllRegions.pending]: (state) => {
            state.loading = true
        },
        [fetchAllRegions.fulfilled]: (state, action) => {
            state.list = action.payload
            state.loading = false
        },
        [fetchRegionById.fulfilled]: (state, action) => {
            state.selectedRegion = action.payload
        }
    }
})

export const {
    usersLoading,
    usersReceived
} = regionSlice.actions

export default regionSlice.reducer